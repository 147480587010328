@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@400;600&display=swap');

.receipt {
  font-family: 'Recursive', sans-serif;
  background: #eee;
  width: 100%;
  max-width: 425px;  /* Reduced from 500px */
  border-radius: 10px;  /* Adjusted from 12px */
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.logo-recibo {
  background: #56f39a;
  padding: 17px;  /* Reduced from 20px */
  text-align: center;
}

.logo-recibo img {
  max-width: 255px;  /* Reduced from 300px */
  height: auto;
}

.amount-section {
  padding: 25px 17px;  /* Reduced from 30px 20px */
  text-align: center;
}

.currency {
  color: #00875f;
  font-size: 0.85rem;  /* Reduced from 1rem */
  margin-bottom: 8px;  /* Reduced from 10px */
}

.amount {
  font-size: 2.125rem;  /* Reduced from 2.5rem */
  font-weight: bold;
  color: #174237;
}

.amount span {
  font-size: 0.85rem;  /* Reduced from 1rem */
  margin-left: 4px;  /* Reduced from 5px */
  color: #202020;
}

.details {
  padding: 0 15px 15px 15px;  /* Reduced from 20px */
}

.detail-item {
  margin-bottom: 17px;  /* Reduced from 20px */
  text-align: center;
}

.detail-item:first-child {
  border-top: 1px solid #56f39a;
  padding-top: 20px;
}

.detail-item:last-child {
  border-bottom: 1px solid #56f39a;
}

.detail-item h2 {
  font-size: 0.68rem;  /* Reduced from 0.8rem */
  color: #202020;
  margin-bottom: 4px;  /* Reduced from 5px */
  font-weight: 600;
}

.detail-item p {
  color: #174237;
  font-size: 0.85rem;  /* Reduced from 1rem */
}

.footer-recibo {
  background: #56f39a;
  color: #174237;
  padding: 17px;  /* Reduced from 20px */
  text-align: center;
  font-size: 0.765rem;  /* Reduced from 0.9rem */
  line-height: 1.4;
}

.footer-recibo strong {
  font-weight: 600;
}
